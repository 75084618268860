import { defineStore } from "pinia";

export const useUpcomingGames = defineStore("upcomingGames", () => {
  const dict = useDictionaries();

  const {
    data: parties,
    status,
    refresh,
    execute,
  } = useAPI("/parties/parties/upcoming/", {
    transform: (source) => enrichOpportunity(enrich(source, dict.dictionaries)),
    lazy: true,
  });

  const partiesLength = computed(() => parties.value?.length || 0);

  return {
    parties,
    partiesLength,
    status,
    refresh,
    execute,
  };
});
